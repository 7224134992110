import React from 'react'
import './Brand.css'
import OurWork from './OurWork'
import headervideo from '../../images/assets/BrandIdentity/header-video.mp4'
import left from '../../images/assets/BrandIdentity/gradient-left.png'
import blurr from '../../images/assets/BrandIdentity/blur-effect.png'
import WeWork from './WeWork'
import Choose from './Choose'
import Reviews from './Reviews'
import Packages from './Packages'
const Brand = () => {
    return (
        <>
            <div className="brand-header">
                <div className='upper' />
                <img src={left} alt="" className='left' />
                <div className='lower' />
                <img src={blurr} alt="" className='blurr' />
                <div className='brand-header-context'>
                    <h1>Your brand is what people say about you when you're not in the room. </h1>
                    <p>Jeff Bezos</p>
                    <button><a href="tel:+1 (307) 2434530">Let's Talk! 🙂</a></button>
                </div>
                <div className='brand-header-video'>
                    <video
                        src={headervideo}
                        playsInline autoPlay muted loop />
                </div>
            </div>

            <OurWork />

            <Packages />

            <WeWork />

            <Choose />

            <Reviews />




        </>
    )
}

export default Brand
