import React from 'react'
import phonemockup from '../../images/assets/BrandIdentity/phone-mockup.png'
import side from '../../images/assets/BrandIdentity/popup-side.svg'
import choosevideo from '../../images/assets/BrandIdentity/choose-video.mp4'
import chooseDNA from '../../images/assets/BrandIdentity/choose-DNA.svg'
import chooseproof from '../../images/assets/BrandIdentity/choose-proof.png'
import choosedesign from '../../images/assets/BrandIdentity/choose-design.svg'
const WhyChooseUs = () => {
    const bullet = [
        {
            imgSrc: chooseDNA,
            heading: 'Strategic Brand DNA',
            para: 'We uncover the soul of your brand, ensuring it resonates authentically and deeply with your audience.'
        },
        {
            imgSrc: chooseproof,
            heading: 'Future-Proof Identity',
            para: 'We create identities that evolve gracefully, ensuring your brand remains relevant setting a new standard in brand longevity.'
        },
        {
            imgSrc: choosedesign,
            heading: 'Approachable Design',
            para: 'We ensure that your ideas are well integrated into the design, creating brand identities that truly reflect your unique perspective.'
        },

    ]
    return (
        <>
            <div className="choose-section-main">
                <div className="choose-section-context">
                    <h1>Why Choose Us?</h1>
                    <div className='choose-bullets'>
                        {bullet.map((bullet, index) => (
                            <div className='bullet-main' key={index}>
                                <div className='bullet-heading'>
                                    <img src={bullet.imgSrc} alt="" />
                                    <h2>{bullet.heading}</h2>
                                </div>
                                <p>{bullet.para}</p>
                            </div>
                        ))}
                    </div>
                    <div className='popup'>
                        <h2>"Design is not just what it looks like and feels <br />like. Design is how it works”</h2>
                        <p>Steve Jobs</p>
                        <img src={side} alt="" className='popup-side' />
                    </div>
                </div>
                <div className="choose-section-video">
                    <img src={phonemockup} alt="" className='phonemockup' />
                    <video src={choosevideo} playsInline autoPlay muted loop />
                    <div className='popup-mob'>
                        <h2>"Design is not just what it looks like and feels like. Design is how it works”</h2>
                        <p>Steve Jobs</p>
                        <img src={side} alt="" className='popup-side' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyChooseUs
