import React from 'react'
import face from '../../images/assets/BrandIdentity/face.png'
import arista from '../../images/assets/BrandIdentity/arista.png'
import andrew from '../../images/assets/BrandIdentity/andrew.png'
import juicygrill from '../../images/assets/BrandIdentity/juicygrill.png'
import slick from '../../images/assets/BrandIdentity/slick.png'
import moods from '../../images/assets/BrandIdentity/moods.png'
import butter from '../../images/assets/BrandIdentity/butter.png'
import one from '../../images/assets/BrandIdentity/one.png'
import bluehorizon from '../../images/assets/BrandIdentity/bluehorizon.png'
import niche from '../../images/assets/BrandIdentity/niche.png'
import uci from '../../images/assets/BrandIdentity/uci.png'
import digitax from '../../images/assets/BrandIdentity/digitax.png'
import falcon from '../../images/assets/BrandIdentity/falcon.png'
const OurWorkAd = () => {

  const images = [
    {
      imgSrc: arista,
      link: '/Arista'
    },
    {
      imgSrc: andrew,
      link: '/Andrew'
    },
    {
      imgSrc: juicygrill,
      link: '/Juicy'
    },
    {
      imgSrc: slick,
      link: '/Slick'
    },
    {
      imgSrc: moods,
      link: '/Moods'
    },
    {
      imgSrc: butter,
      link: '/Butter'
    },
    {
      imgSrc: one,
      link: '/One'
    },
    {
      imgSrc: bluehorizon,
      link: '/BlueHorizon'
    },
    {
      imgSrc: niche,
      link: '/Niche'
    },
    {
      imgSrc: uci,
      link: '/UCI'
    },
    {
      imgSrc: digitax,
      link: '/Digitax'
    },
    {
      imgSrc: falcon,
      link: '/Falcon'
    },

  ]
  const imagesright = [
    {
      imgSrc: falcon,
      link: '/Falcon'
    },
    {
      imgSrc: digitax,
      link: '/Digitax'
    },
    {
      imgSrc: uci,
      link: '/UCI'
    },
    {
      imgSrc: niche,
      link: '/Niche'
    },
    {
      imgSrc: bluehorizon,
      link: '/BlueHorizon'
    },
    {
      imgSrc: one,
      link: '/One'
    },
    {
      imgSrc: butter,
      link: '/Butter'
    },
    {
      imgSrc: moods,
      link: '/Moods'
    },
    {
      imgSrc: slick,
      link: '/Slick'
    },
    {
      imgSrc: juicygrill,
      link: '/Juicy'
    },
    {
      imgSrc: andrew,
      link: '/Andrew'
    },
    {
      imgSrc: arista,
      link: '/Arista'
    },

  ]

  return (
    <div>
      <div className='ourwork-main'>
        <div className='ourwork-context'>
          <img src={face} alt="" />
          <h1>Our Work</h1>
          <p>From minimalism to maximalism! As brands mature and grow, it becomes more important to introduce a richer, more extravagant identity that engages and captures their audiences.</p>
        </div>
        <div className='ourwork-images'>
          <div className='images-left'>
            {images.map((data, index) => (
              <div key={index}>
                <img src={data.imgSrc} alt="" />
                <button><a href={data.link} target="_blank" rel="noopener noreferrer">See more</a></button>
              </div>
            ))}
          </div>
          <div className='images-right'>
            {imagesright.map((data, index) => (
              <div key={index}>
                <img src={data.imgSrc} alt="" />
                <button><a href={data.link} target="_blank" rel="noopener noreferrer">See more</a></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurWorkAd
