import React, { useEffect, useRef, useState } from 'react';
import img from '../images/assets/navlogo.svg';
import imgservisden from '../images/sevisdenpng.png';
import '../../src/style.css';
import { NavLink } from 'react-router-dom';
import qoutearrow from '../images/assets/qoutearrow.svg'
import qouteform from '../images/assets/qouteform.svg'
import callicon from '../images/assets/BrandIdentity/callicon.svg'
import CountrySelector from './Country Selector/CountrySelector'
import { Modal } from 'react-bootstrap'


const Navbar = () => {
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [showModal, setShowModal] = useState()
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);

  const handleNavLinkClick = (path) => {
    setActiveTab(path);
  };

  const handleDropdownItemClick = (path) => {
    setActiveTab('/Services');
  };
  const isServicesActive = activeTab === '/Services';

  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    services: '',
    message: '',
    agreement: false,
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      setShowForm(false);
      setShowThankYou(true);
      const config = {
        SecureToken: '23848585-a9b3-4429-a9f9-033ba92af790',
        To: 'info@wurfelit.com',
        From: "info@wurfelit.com",
        Subject: 'CONSULTATION',
        Body: `Name :${formData.name}, 
             Email: ${formData.email}, 
             Company: ${formData.services}, 
             Country: ${formData.country.value}, 
             Phone: ${formData.phoneNumber}, 
             MESSAGE : ${formData.message}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }
    if (!data.services) {
      errors.services = 'Services field is required';
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }
    if (!data.agreement) {
      errors.agreement = 'You must agree to the terms and conditions';
    }
    if (!data.message) {
      errors.message = 'Message field is required';
    }
    return errors;
  };
  return (
    <>
      <div className='container-fluid nav_bg'>
        <div className='row'>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a href='/'><img src={imgservisden} className='image-logo' alt='' /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                </span>
              </button>
              <div className="navbar-collapse collapse" id="navbarSupportedContent" style={{ flexGrow: '0' }}>
                <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                  <li className={`nav-item ${activeTab === '/' && 'active'}`}>
                    <NavLink exact className="nav-link" to="/" onClick={() => handleNavLinkClick('/')}>Home</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink exact className="nav-link" to="/About" onClick={() => handleNavLinkClick('/')}>About Us</NavLink>
                  </li> */}
                  <li className={`nav-item dropdown ${isServicesActive ? 'active' : ''}`}>
                    <NavLink exact className="nav-link dropdown-toggle" to="/Services" role="button" data-bs-toggle="dropdown">
                      Services
                    </NavLink>
                    <ul className="dropdown-menu">
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/Branding' onClick={() => handleDropdownItemClick('/Branding')}>Branding</a></li>
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/WebDevelopment' onClick={() => handleDropdownItemClick('/WebDevelopment')}>Web Development</a></li>
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/SMM' onClick={() => handleDropdownItemClick('/SMM')}>Social Media Marketing</a></li>
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/Graphic' onClick={() => handleDropdownItemClick('/Graphic')}>Graphic Designing</a></li>
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/Content' onClick={() => handleDropdownItemClick('/Content')}>Content Marketing</a></li>
                      <li><a className={`dropdown-item ${activeTab.startsWith('/Services') && 'active'}`} href='/Identity' onClick={() => handleDropdownItemClick('/Identity')}>Brand Identity</a></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink exact className="nav-link" to="/Work" onClick={() => handleNavLinkClick('/')}>Our Work</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink exact className="nav-link" to="/Career" onClick={() => handleNavLinkClick('/')}>Career</NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink exact className="nav-link" to="/Plans" onClick={() => handleNavLinkClick('/')}>Plans</NavLink>
                  </li>
                  <button className='get-qoute'><a href="tel:+1 (307) 3234769">+1 (307) 323-4769</a><img src={callicon} alt=''/></button>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Modal
        show={showModal}>
        <div className='qouteform'>
          <div className='formtop'>
            <button style={{ borderWidth: '0px', background: 'none' }}><img src={qoutearrow} alt="" onClick={handleCloseModal} /></button>
          </div>
          <div className='formimg'>
            <img src={qouteform} />
            <h1>Do you want help </h1>
            <p>Get us on board! </p>
          </div>
          <div className='getqouteform'>
            <form ref={form} onSubmit={handleSubmit}>
              <div className='qouteformcon'>
                <div className='qoutedata'>
                  {errors.name && <span className="error">{errors.name}</span>}
                  <input
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    name="name"
                    placeholder='Enter Your Name'
                    className='name'
                    required />
                </div>
                <div className='qoutedata'>
                  {errors.email && <span className="error">{errors.email}</span>}
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='You@example.com'
                    className='email'
                    required />
                </div>
                {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                <div className='qoutedata d-flex' style={{ flexDirection: 'row' }}>
                  <CountrySelector setData={setFormData} data={formData} />
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder='xxxxxxxxxx'
                    className='input2'
                    required />
                </div>
                <div className='qoutedata'>
                  {errors.services && <span className="error">{errors.services}</span>}
                  <input
                    type="text"
                    placeholder='Services Required'
                    value={form.services}
                    required
                  />
                </div>
                <button type='submit' className='qoutesendbutton' onClick={handleSubmit}>Send<span className='moreinfoarrow'>&#8594;</span></button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
