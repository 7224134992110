import React, { useRef, useState } from 'react'
import './AdsPage.css'
import blurr from '../images/assets/BrandIdentity/blur-effect.png'
import CountrySelector from '../Components/Country Selector/CountrySelector'



import { useNavigate } from 'react-router-dom'
import OurWorkAd from './Ads/OurWorkAd'
import WhyChooseUs from './Ads/WhyChooseUs'
import ReviewsAd from './Ads/ReviewsAd'

const AdsPage = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        services: '',
    });
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: newValue });
    };
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        console.log(Object.keys(validationErrors).length)

        if (Object.keys(validationErrors).length === 0) {
            const config = {
                SecureToken: '07724863-db2a-4792-90cc-ad468c51b46e',
                To: 'info@wurfelit.co',
                From: "info@wurfelit.co",
                Subject: 'ADS',
                Body: `Name :${formData.name}, 
                Email: ${formData.email}, 
                Country: ${formData.country.value}, 
                Phone: ${formData.phoneNumber}, 
                Services Required : ${formData.services}`
            }
            window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d));
            navigate('/Thankyou');
            setTimeout(() => navigate('/Ad'), 5000);
        } else {
            setErrors(validationErrors);
        }
    };
    const validateForm = (data) => {
        const errors = {};

        if (!data.name || data.name.length < 3 || data.name.length > 25) {
            errors.name = 'Name should be between 3 and 25 characters*';
        }

        if (!data.email) {
            errors.email = 'Email is required*';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Invalid email format*';
        }

        if (!data.country) {
            errors.country = 'Country is required*';
        }
        if (!data.services) {
            errors.services = 'Services field is required';
        }
        if (!data.phoneNumber) {
            errors.phoneNumber = 'Phone number is required*';
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number format';
        }
        return errors;
    };




    return (
        <>
            <div className="brand-header">
                <img src={blurr} alt="" className='blurr' />
                <div className='brand-header-context'>
                    <h1>Your brand is what people say about you when you're not in the room. </h1>
                    <p>Jeff Bezos</p>
                </div>
                <div className='brand-header-form'>
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className='qouteformcon'>
                            <div className='qoutedata-ad'>
                                {errors.name && <span className="error">{errors.name}</span>}
                                <input type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                    name="name"
                                    placeholder='Enter Your Name'
                                    className='name'
                                    required />
                            </div>
                            <div className='qoutedata-ad'>
                                {errors.email && <span className="error">{errors.email}</span>}
                                <input type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder='You@example.com'
                                    className='email'
                                    required />
                            </div>
                            <div className='codenumber'>
                                {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                                <div className='qoutedata-ad d-flex' style={{ flexDirection: 'row', gap: '10px', alignSelf: 'center', width: '100%' }}>
                                    <CountrySelector setData={setFormData} data={formData} />
                                    <input type="text"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        placeholder='xxxxxxxxxx'
                                        className='input2'
                                        required />
                                </div>
                            </div>
                            <div className='qoutedata-ad'>
                                {errors.services && <span className="error">{errors.services}</span>}
                                <input
                                    name='services'
                                    type="text"
                                    placeholder='Services Required'
                                    value={form.services}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type='submit' className='qoutesendbutton' onClick={handleSubmit}>Submit<span className='moreinfoarrow'>&#8594;</span></button>
                        </div>
                    </form>
                </div>
            </div>

           <OurWorkAd/>

            <WhyChooseUs/>

           <ReviewsAd/>
        </>
    )
}

export default AdsPage
