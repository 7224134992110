import React from 'react'
import '../style.css'
import dash from '../images/assets/dash.svg'
const Thankyou = () => {
  return (
    <div>
       <div className='thankyou'>
          <div className='thankyoutxt'>
            <h1>Thank You</h1>
            <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
            <p>We appreciate the time you've taken to get in touch and are committed
              to responding as quickly as possible. Our team will review your message and you
              can expect to hear back from us within <b>24-48 hours</b></p>
          </div>
        </div>
    </div>
  )
}

export default Thankyou
